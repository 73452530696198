<template>
  <div class="the-total" slot="header-content">
    <div class="the-total__list">
      <span class="the-total__item">
        Total:
        <span class="the-total__value">{{ renderTotal }}</span>
      </span>
    </div>

    <DatePicker @input="value => date = value"/>
  </div>
</template>

<script>
import DatePicker from "@/components/root/DatePicker";

import { axios } from "../utils/axios";

export default {
  name: "the-total",

  components: {
    DatePicker
  },

  props: {
    name: {
      type: String,
      default: "deposits"
    }
  },

  data: () => ({
    totals: null,

    date: null
  }),

  computed: {
    renderTotal() {
      return this.totals ? this.totals[this.name] : "-";
    }
  },

  watch: {
    date(next) {
      if (next) this.getTotal();
      else this.totals = null;
    }
  },

  methods: {
    getTotal() {
      axios
        .get(`/api/users/totals/?from=${this.date.start}&to=${this.date.end}`)
        .then(response => {
          if (response.status === 200) {
            this.totals = response.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.the-total {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-right: 1rem;
  }

  &__value {
    color: #ea4032;
  }
}
</style>